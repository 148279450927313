<template>
  <v-container class="card-check pa-0 pa-sm-3 mt-4 mt-sm-0" fluid>
    <!-- TAB 1 (utente ha la card) -->
    <v-container
      class="default--text my-0 my-lg-10 pa-0 pa-sm-3 text-left align-center align-md-start"
    >
      <div class="font-weight-bold mb-5 px-3 text-body-1 text-sm-h4">
        {{ $t("register.cardCheck.title") }}
      </div>
      <v-form ref="cardform" v-model="isCardFormValid">
        <v-row dense no-gutters>
          <v-col cols="12" sm="6">
            <!-- login -->
            <!-- <div class="text-body-2 mb-1">
              {{ `${$t("register.cardCheck.email")}*` }}
            </div> -->
            <v-text-field
              v-model="registerData.login"
              :rules="emailRules"
              :label="`${$t('register.cardCheck.email')}*`"
              required
              filled
              solo
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <!-- conferma login -->
            <!-- <div class="text-body-2 mb-1">
              {{ `${$t("register.cardCheck.emailConfirm")}*` }}
            </div> -->
            <v-text-field
              :rules="emailConfirmRules"
              :label="`${$t('register.cardCheck.emailConfirm')}*`"
              required
              filled
              solo
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <!-- password -->
            <!-- <div class="text-body-2 mb-1">
              {{ `${$t("register.cardCheck.password")}*` }}
            </div> -->
            <v-text-field
              v-model="registerData.password"
              :append-icon="showPassword ? '$eye' : '$eyeOff'"
              :type="showPassword ? 'text' : 'password'"
              :rules="passwordRules"
              @click:append="toggleShowPassword"
              :label="`${$t('register.cardCheck.password')}*`"
              required
              filled
              solo
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <!-- conferma password -->
            <!-- <div class="text-body-2 mb-1">
              {{ `${$t("register.cardCheck.passwordConfirm")}*` }}
            </div> -->
            <v-text-field
              :append-icon="showPassword ? '$eye' : '$eyeOff'"
              :type="showPassword ? 'text' : 'password'"
              :rules="passwordConfirmRules"
              @click:append="toggleShowPassword"
              :label="`${$t('register.cardCheck.passwordConfirm')}*`"
              required
              filled
              solo
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- nome -->
            <!-- <div class="text-body-2 mb-1">
              {{ `${$t("register.cardCheck.firstName")}*` }}
            </div> -->
            <v-text-field
              v-model="registerData.person.firstName"
              :rules="[requiredRules()]"
              :label="`${$t('register.cardCheck.firstName')}*`"
              required
              filled
              solo
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- cognome -->
            <!-- <div class="text-body-2 mb-1">
              {{ `${$t("register.cardCheck.lastName")}*` }}
            </div> -->
            <v-text-field
              v-model="registerData.person.lastName"
              :rules="[requiredRules()]"
              :label="`${$t('register.cardCheck.lastName')}*`"
              filled
              solo
              dense
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- data di nascita -->
            <!-- <div class="text-body-2 mb-1">
              {{ `${$t("register.cardCheck.birthDate")}*` }}
            </div> -->
            <v-text-field
              v-model="birthDate"
              :rules="dateRules"
              :label="`${$t('register.cardCheck.birthDate')}*`"
              v-mask="'##/##/####'"
              placeholder="__/__/____"
              required
              filled
              solo
              dense
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" v-if="cardFieldEnabled">
            <!-- numero carta -->
            <!-- <div class="text-body-2 mb-1">
              {{ `${$t("register.fidelityCard")}*` }}
            </div> -->
            <v-text-field
              v-model="registerData.fidelityCard"
              :rules="fidelityCardRules"
              :label="`${$t('register.cardCheck.fidelityCard')}*`"
              type="tel"
              required
              filled
              v-mask="'###############'"
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row no-gutters>
        <v-checkbox class="ma-0" hide-details required v-model="checked">
          <template v-slot:label>
            <i18n path="register.cardCheck.acceptConditionsText" tag="span">
              <template v-slot:link>
                <router-link to="/page/privacy-policy">
                  <span class="font-weight-bold default--text">
                    {{ $t("register.cardCheck.acceptConditionsLink") }}</span
                  >
                </router-link>
              </template>
            </i18n>
          </template>
        </v-checkbox>
      </v-row>
      <ResponseMessage :response="response" class="mt-4" />
      <v-row
        no-gutters
        class="d-flex justify-center justify-md-end mt-10 mt-sm-6"
      >
        <v-btn
          color="primary"
          x-large
          elevation="0"
          min-width="185"
          :block="$vuetify.breakpoint.xs"
          :disabled="!isCardFormValid || !checked"
          @click="checkUser()"
        >
          {{ $t("register.cardCheck.continue") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>
<style scoped lang="scss">
.card-check {
  div[class*="col-"] {
    padding: 0 12px;
  }
  a {
    color: var(--v-default-base);
  }
}
</style>
<script>
import {
  requiredValue,
  isEmail,
  isNumber
  // isDate,
} from "~/validator/validationRules";
import { mask } from "vue-the-mask";
import PoliCustomService from "@/service/poliCustomService";
import ResponseMessage from "@/components/ResponseMessage";
import { req } from "~/validator/commons";

export default {
  props: { cardFieldEnabled: { type: Boolean, default: true } },
  directives: { mask },
  components: { ResponseMessage },
  data() {
    return {
      isCardFormValid: false,
      showPassword: false,
      birthdateMenu: false,
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        this.customPasswordRule()
      ],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.registerData.login || "Le e-mail non coincidono"
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.registerData.password || "Le password non coincidono"
      ],
      registerData: {
        person: {},
        billingAddress: {}
      },
      checked: false,
      response: {}
    };
  },
  computed: {
    birthDate: {
      get: function() {
        if (this.registerData.person.birthDate) {
          return this.$dayjs(this.registerData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.registerData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    customPasswordRule(error) {
      // eslint-disable-next-line no-unused-vars
      var errorMessage = error
        ? error
        : "La password deve essere lunga almeno 8 caratteri, contenere maiuscole, minuscole e almeno un numero";
      return function(v) {
        const errorMessage =
          "La password deve contenere almeno 8 caratteri, una maiuscola, una minuscola e un numero";
        const minLength = /.{8,}/.test(v);
        const hasUppercase = /[A-Z]/.test(v);
        const hasLowercase = /[a-z]/.test(v);
        const hasNumber = /[0-9]/.test(v);
        return (
          // eslint-disable-next-line no-undef
          !req(v) ||
          (minLength && hasUppercase && hasLowercase && hasNumber) ||
          errorMessage
        );
      };
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    /**
     * registrazione e conseguente invio di link via email
     * per utenti con carta
     */
    async checkUser() {
      try {
        if (this.$refs.cardform.validate()) {
          // TEST
          // this.$emit("next", { step: 2, user: this.registerData });
          this.loading = true;
          this.response = {};
          let response = await PoliCustomService.checkCard(this.registerData);
          this.loading = false;
          if (response && response.status === 0) {
            this.$emit("next", { step: 2, user: this.registerData });
          } else {
            if (response && response.errors.length) {
              this.response = response;
            }
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
