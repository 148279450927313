<template>
  <v-container class="d-flex flex-column">
    <!-- INTESTAZIONE desktop -->
    <CategoryTitle :category="category" class="mt-0 mb-6 my-lg-6" />

    <!-- STEPPER -->
    <v-container class="pa-0">
      <v-stepper
        class="stepper"
        v-model="currentStep"
        :change="toTop()"
        elevation="0"
      >
        <v-container
          class="pa-10 primary lighten-2 steps"
          v-if="currentStep === 1"
        >
          <h4
            class="text-center font-weight-bold mb-10"
            v-if="currentStep === 1"
          >
            {{ $t("register.switch-text") }}
          </h4>
          <div>
            <v-row justify="center" no-gutters>
              <v-btn
                color="primary"
                class="mx-4"
                large
                min-width="113"
                :outlined="!cardFieldEnabled"
                depressed
                @click="cardFieldEnabled = true"
                >{{ $t("common.yes") }}</v-btn
              >
              <v-btn
                color="primary"
                class="mx-4"
                large
                min-width="113"
                :outlined="cardFieldEnabled"
                depressed
                @click="cardFieldEnabled = false"
                >{{ $t("common.no") }}</v-btn
              >
            </v-row>
          </div>
        </v-container>

        <v-stepper-header class="mx-auto">
          <template v-for="n in steps">
            <v-stepper-step
              color="primary lighten-1"
              :key="steps.indexOf(n)"
              :complete="currentStep > steps.indexOf(n) + 1"
              :step="steps.indexOf(n) + 1"
            />

            <v-divider
              v-if="steps.indexOf(n) + 1 < steps.length"
              :key="`${steps.indexOf(n)}-divider`"
            ></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content class="pa-0" step="1">
            <CardCheck @next="nextStep" :cardFieldEnabled="cardFieldEnabled" />
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="2">
            <CompleteUserData
              @next="nextStep"
              :registerData="navigationParams.user"
            />
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="3">
            <!-- registrazione completata -->
            <RegistrationCompleted :registerData="navigationParams.user" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.stepper {
  box-shadow: none;
  -webkit-box-shadow: none;
  .steps {
    border-radius: 6px;
    border: 1px solid $gray-border-color !important;
  }
  .v-stepper__header {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 60%;
    }
  }
}
.v-input {
  font-weight: 600;
  color: var(--v-default-base);
  font-size: 14px;
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CardCheck from "@/components/registration/CardCheck.vue";
import CompleteUserData from "@/components/registration/CompleteUserData.vue";
import RegistrationCompleted from "@/components/registration/RegistrationCompleted.vue";

import categoryMixins from "~/mixins/category";

export default {
  mixins: [categoryMixins],
  components: {
    CategoryTitle,
    CardCheck,
    CompleteUserData,
    RegistrationCompleted
  },
  data() {
    return {
      steps: [{ name: 1 }, { name: 2 }, { name: 3 }],
      cardFieldEnabled: true,
      currentStep: 1,
      navigationParams: {}
    };
  },
  methods: {
    toTop() {
      //TODO: verificare l'animazione su dispositivi
      // this.$nextTick(() => {
      //   this.$vuetify.goTo(0);
      // });
    },
    nextStep(params) {
      this.navigationParams = {};
      if (params) {
        this.navigationParams = params;
      }
      this.currentStep = params.step;
    }
  },
  watch: {
    steps(val) {
      if (this.currentStep > val) {
        this.currentStep = val;
      }
    }
  }
};
</script>
