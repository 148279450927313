<template>
  <v-container class="registration-completed px-0">
    <v-container class="default--text px-0">
      <div class="font-weight-bold mb-5 text-body-1 text-sm-h4">
        {{ $t("register.registrationCompleted.title") }}
      </div>
      <h5>{{ $t("register.registrationCompleted.desc1") }}</h5>
      <h5 class="font-weight-bold">
        {{ $t("register.registrationCompleted.desc2") }}
      </h5>
    </v-container>
  </v-container>
</template>

<script>
export default {
  props: {
    registerData: { type: Object }
  },
  data() {
    return {
      emailSent: false
    };
  }
};
</script>
