<template>
  <v-container class="complete-user-data pa-0 pa-sm-3 mt-4 mt-sm-0">
    <v-container class="default--text pa-0 pa-sm-3">
      <div class="font-weight-bold mb-5 px-3 text-body-1 text-sm-h4">
        {{ $t("register.completeData.title") }}
      </div>

      <v-form ref="form" v-model="isValid" v-if="registerData">
        <v-row dense no-gutters>
          <v-col cols="12" sm="6">
            <!-- indirizzo -->
            <div class="text-caption mb-1">
              {{ `${$t("register.completeData.address")}*` }}
            </div>
            <v-text-field
              v-model="registerData.billingAddress.address1"
              :rules="[requiredValue()]"
              required
              filled
              solo
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- numero civico -->
            <div class="text-caption mb-1">
              {{ `${$t("register.completeData.addressNumber")}*` }}
            </div>
            <v-text-field
              v-model="registerData.billingAddress.addressNumber"
              :rules="[requiredValue()]"
              required
              filled
              solo
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- città -->
            <div class="text-caption mb-1">
              {{ `${$t("register.completeData.city")}*` }}
            </div>
            <v-autocomplete
              v-model="registerData.billingAddress.city"
              :items="cities"
              required
              filled
              dense
              solo
              return-object
              append-icon=""
              height="40"
              item-text="name"
              item-value="name"
              @focus="loadCitiesList()"
              @input="setCityAndProvince"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- provincia -->
            <div class="text-caption mb-1">
              {{ `${$t("register.completeData.province")}*` }}
            </div>
            <v-text-field
              v-model="registerData.billingAddress.province"
              :rules="[requiredValue()]"
              required
              readonly
              filled
              solo
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <!-- cellulare -->
            <div class="text-caption mb-1">
              {{ `${$t("register.completeData.phone")}*` }}
            </div>
            <v-text-field
              v-model="registerData.phone"
              :rules="[requiredValue()]"
              required
              v-mask="'###############'"
              filled
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <div class="font-weight-bold mb-5 text-body-1 text-sm-h4">
          {{ $t("register.completeData.preferencesTitle") }}
        </div>

        <!-- adesione programma DupliCard per te -->
        <!-- <h5 class="font-weight-bold">
          {{ $t("register.completeData.preferencesOptIn1") }}
        </h5>
        <v-radio-group dense v-model="personInfos[1].value" mandatory row>
          <v-radio color="default" :value="true"
            ><template v-slot:label>
              <span class="">{{ $t("common.yes") }}</span>
            </template></v-radio
          >
          <v-radio color="default" class="w-20" :value="false"
            ><template v-slot:label>
              <span class="">{{ $t("common.no") }}</span>
            </template></v-radio
          ></v-radio-group
        > -->

        <!-- mezzo di comunicazione preferito -->
        <!-- <h5 class="font-weight-bold">
          {{ $t("register.completeData.preferencesOptIn2") }}
        </h5>
        <v-radio-group dense v-model="personInfos[2].value" mandatory row>
          <v-radio color="default" :value="'email'"
            ><template v-slot:label>
              <span class="">{{ $t("register.completeData.option1") }}</span>
            </template></v-radio
          >
          <v-radio color="default" :value="'sms'"
            ><template v-slot:label>
              <span class="">{{ $t("register.completeData.option2") }}</span>
            </template></v-radio
          >
          <v-radio color="default" :value="'coupon'"
            ><template v-slot:label>
              <span class="">{{ $t("register.completeData.option3") }}</span>
            </template></v-radio
          ></v-radio-group
        > -->

        <!-- newsletter -->
        <!-- <h5 class="font-weight-bold">
          {{ $t("register.completeData.preferencesOptIn3") }}
        </h5>
        <v-radio-group
          dense
          v-model="registrationModule.registrationModuleDisclaimers[2].value"
          mandatory
          row
        >
          <v-radio color="default" :value="'true'"
            ><template v-slot:label>
              <span class="">{{ $t("common.yes") }}</span>
            </template></v-radio
          >
          <v-radio color="default" :value="'false'"
            ><template v-slot:label>
              <span class="">{{ $t("common.no") }}</span>
            </template></v-radio
          ></v-radio-group
        > -->

        <div class="mb-5">
          <!-- autorizzazione per fini statistici -->
          <v-checkbox
            hide-details
            required
            v-model="registrationModule.registrationModuleDisclaimers[0].value"
            false-value="false"
            true-value="true"
          >
            <template v-slot:label>
              <i18n path="register.completeData.acceptPre" tag="span">
                <template v-slot:link>
                  <router-link :to="'/page/trattamento-dati-statistici'">
                    <span class="font-weight-bold default--text">
                      {{ $t("register.completeData.consent") }}</span
                    >
                  </router-link>
                </template>
              </i18n>
            </template>
          </v-checkbox>

          <!-- autorizzazione per fini di marketing -->
          <v-checkbox
            hide-details
            required
            v-model="registrationModule.registrationModuleDisclaimers[1].value"
            false-value="false"
            true-value="true"
          >
            <template v-slot:label>
              <i18n path="register.completeData.acceptPre" tag="span">
                <template v-slot:link>
                  <router-link :to="'/page/trattamento-dati-marketing'">
                    <span class="font-weight-bold default--text">
                      {{ $t("register.completeData.marketing") }}</span
                    >
                  </router-link>
                </template>
              </i18n>
            </template>
          </v-checkbox>
        </div>

        <!-- negozio abituale -->
        <!-- <h5 class="">
          {{ $t("register.completeData.preferredPdv") }}
        </h5>
        <v-select
          v-model="personInfos[5].value"
          item-text="billingAddress.name"
          item-value="billingAddress.addressId"
          required
          filled
          solo
          dense
          :items="warehouses"
        ></v-select> -->
      </v-form>

      <v-row no-gutters class="d-flex justify-center justify-md-end">
        <v-btn
          color="primary"
          x-large
          elevation="0"
          :disabled="!isValid"
          min-width="185"
          :block="$vuetify.breakpoint.xs"
          :outlined="$vuetify.breakpoint.xs"
          @click="submit()"
        >
          {{ $t("register.completeData.registerButton") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>
<style scoped lang="scss">
.complete-user-data {
  .text-caption {
    margin-left: 12px;
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  div[class*="col-"] {
    padding: 0 12px;
  }
  a {
    color: var(--v-default-base);
  }
  .v-radio {
    min-width: 100px;
  }
  .icon-checkbox-on {
    color: var(--v-default-base) !important;
  }
}
</style>
<script>
import poliCustomService from "@/service/poliCustomService";
import addressService from "~/service/addressService";
import { requiredValue } from "~/validator/validationRules";
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  props: {
    registerData: {
      type: Object,
      required: true,
      default: () => {
        return { billingAddress: {}, person: { personInfos: [] } };
      }
    }
  },
  data() {
    return {
      isValid: false,
      requiredValue: requiredValue,
      cities: [],
      warehouses: [],
      personInfos: [
        { personInfoTypeId: 101, value: "false" }, // autorizzazione per fini di marketing
        { personInfoTypeId: 104, value: "false" }, // programma Duplicard per te
        { personInfoTypeId: 105, value: "email" }, // mezzo di comunicazione preferito
        { personInfoTypeId: 103, value: "false" }, // autorizzazione per fini statistici
        { personInfoTypeId: 102, value: "false" }, // newsletter
        { personInfoTypeId: 106, value: "770" } // negozio abituale
      ],
      registrationModule: {
        registrationModuleAction: {
          name: "Inserimento",
          registrationModuleActionId: 2
        },
        registrationModuleDisclaimers: [
          {
            // Fini Statistici
            registrationDisclaimer: {
              registrationDisclaimerId: 1
            },
            value: "false"
          },
          {
            // Marketing
            registrationDisclaimer: {
              registrationDisclaimerId: 2
            },
            value: "false"
          },
          {
            // Newsletter
            registrationDisclaimer: {
              registrationDisclaimerId: 3
            },
            value: "false"
          }
        ],
        registrationModuleType: {
          description: "",
          name: "REG_WEB",
          registrationModuleTypeId: 2
        }
      }
    };
  },
  methods: {
    async loadCitiesList() {
      if (!this.cities.length) {
        let data = await poliCustomService.getCityList();
        if (data) {
          this.cities = data;
        }
      }
    },
    setCityAndProvince(value) {
      this.registerData.billingAddress.city = value.name;
      this.registerData.billingAddress.province = value.province.name;
    },
    async fetchWarehouses() {
      let data = await addressService.findWarehouse({
        parent_company_id: 1
      });
      if (data.warehouses.length) {
        this.warehouses = data.warehouses;
      }
    },
    async submit() {
      let that = this;
      try {
        this.loading = true;
        if (this.$refs.form.validate()) {
          that.registerData.registrationModule = that.registrationModule;
          // that.registerData.person.personInfos = that.personInfos;
          // TEST
          // this.$emit("next", { step: 3, user: this.registerData });
          let res = await poliCustomService.confirmRegistration(
            that.registerData
          );
          this.loading = false;
          if (
            !res ||
            (res.response.errors.length && res.response.errors[0].error)
          ) {
            global.EventBus.$emit("error", {
              message: res.response.errors[0].error
            });
          } else {
            this.$emit("next", { step: 3, user: this.registerData });
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
